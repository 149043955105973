import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import App from './App.vue'
import routes from './routes.js';

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes,
})

const app = createApp(App)
app.use(router)
app.mount('#app')
