<template>
  <div id="app">
    <Header/>
    <main role="main">
      <div class="container">
        <router-view/>
      </div>
    </main>
  </div>
</template>

<script>
import Header from './Header.vue'

export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style>
    #app {
        font-family: Roboto, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>
