<template>
  <header>
    <nav class="navbar navbar-dark navbar-expand-lg " style="background-color: rgb(86, 61, 124);">
      <div class="container">
        <router-link to="/" class="nav-link">
          <span class="navbar-brand mb-0 h1 pull-left nav-link">Fourensics</span>
        </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item">

            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link">About</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>


<style>
  .navbar-brand {
    font-family: 'Spicy Rice', cursive;
    opacity: 0.5;
  }

  .nav-link.active .navbar-brand {
    opacity: 1;
  }

  .nav-link {
    outline: 0;
  }

  /*noinspection CssUnusedSymbol*/
  .nav-link.active {
    font-weight: bold;
  }

</style>
