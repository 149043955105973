<template>
  <Game></Game>
</template>

<script>

import { defineAsyncComponent } from 'vue';
const asyncGame = defineAsyncComponent(() => import('@/game/Game.vue'))

// import Game from '@/game/Game.vue'

export default {
  name: '  ',
  components: {
    Game: asyncGame,
  }
}
</script>
